<script lang="tsx">
import { defineComponentBaseUiProgressBar } from '@core/app/components/base/ui/BaseUiProgressBar.vue'

export default defineComponentBaseUiProgressBar({
    props: {
        breakpoints: {
            default: [30, 100],
        },
    },
})

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiProgressBar" as *;

@include progress {
    border-radius: 100vmax;
    height: 0.5rem;
}

@include progress-bar {
    background-color: $sim-c-basic-400;
}

@include progress-value {
    background-color: $sim-c-danger-500;
}

@include progress-value('30') {
    background-color: $sim-c-warning-500;
}

@include progress-value('100') {
    background-color: $sim-c-success-500;
}

</style>
